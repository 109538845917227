import React, { useEffect, useState } from 'react';
import { StyledAutocomplete, StyledInput } from '../Util/Comp/SmallComp';
import { fetchMasterData, formatDate } from '../Util/CommonFun/CommonFun';
import { Autocomplete, Box, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Check } from '@mui/icons-material';
import axios from 'axios';
import VoucherTable from '../Util/Comp/VoucherTable';
import useImportVoucher from '../hooks/useImportVoucher';
import dayjs from "dayjs";


function VoucherEntey() {

  const { dataForEdit, setDateforEdit, voucherEditing, setVoucherEditing } = useImportVoucher();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [schemeMaster, setSchemetMaster] = useState([]);
  const [selectedSchemeName, setselectedSchemeName] = useState("")
  const [selectScheme, setSelectScheme] = useState({
    schemeName: "",
    schemeId: ""
  });

  const [accountMaster, setaccountMaster] = useState([])
  const [selectedAccountName, setselectedAccountName] = useState("")
  const [selectAccount, setSelectAccount] = useState({
    acc_name: "",
    acc_id: ""
  });
  const [chequeNo, setChequeNo] = useState("");
  const [voucherDate, setVoucherDate] = useState(null)
  const [chequeDate, setChequeDate] = useState(null)
  const [newVoucher, setNewVoucher] = useState("");
  const [voucherno, setVoucherno] = useState("");
  const [selectedMon, setSelectedMon] = useState("");
  const [bank, setBank] = useState("");
  const [description, setDescrpition] = useState("")
  const [accountType, setAccountType] = useState("");
  const [generateAccDet, setGenerateAccDet] = useState("");
  const [editFlag, setEditFlag] = useState(false)
  const [accountTo, setAccountTo] = useState();
  const [accountFrom, setAccountFrom] = useState();
  const [voucherType, setVoucherType] = useState([
    "BR",
    "BP",
    "CR",
    "CP",
    "JV",
    "CO"
  ]);
  const [voucherRows, setVouncherRows] = useState([
    // { accountTy: 'to', accountDesName: 'SBI KHORDHA 11276460214', accountTo: 10000, accountFrom: 0 },
    // { accountTy: 'from', accountDesName: 'DIST ESTABLISHMENT', accountTo: 0, accountFrom: 5000 }
  ])
  const [submitTrue, setSubmitTrue] = useState(false)

  useEffect(() => {
    async function fetchingSchemeToState() {
      try {
        const response = await fetchMasterData("scheme");
        setSchemetMaster(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchingSchemeToState();
  }, [])

  console.log("dataforEdit444444444444444", dataForEdit,)

  useEffect(() => {
    function checkForEdit() {
      if (Object.keys(dataForEdit).length !== 0) {
        let editAble = dataForEdit["inword_vchentry"].map((item, i) => ({
          ...item,
          // vch_date: item.vch_date.split("T")[0],
          // vch_date: formatDate(item.vch_date),
          // vch_date: dayjs(item.vch_date).format("YYYY-MM-DD"),
          accountTy: item.vch_ty,
          accountDesName: item.account_desc,
          accountTo: item.vch_ty === 'to' ? +item.amount_pay : 0,
          accountFrom: item.vch_ty === 'from' ? +item.amount_rec : 0,
        }))
        editAble = editAble.map(item => {
          delete item.account_desc;
          delete item.amount_pay;
          delete item.amount_rec;
          delete item.vch_date;
          delete item.vch_ty;
          delete item.id;
          return item;
        });
        setVouncherRows(editAble)
        let EditVoucherDate = dayjs(dataForEdit["outward_vchentry"][0]["vch_date"])
        let EditCheckDate = dataForEdit["outward_vchentry"][0]["chq_date"] === "1899-11-29T18:38:50.000Z" ? null : dayjs(dataForEdit["outward_vchentry"][0]["chq_date"])
        setVoucherno(dataForEdit["outward_vchentry"][0]["vch_no"])
        setselectedSchemeName(dataForEdit["outward_vchentry"][0]["scheme_name"])
        setNewVoucher(dataForEdit["outward_vchentry"][0]["vch_type"])
        setChequeNo(dataForEdit["outward_vchentry"][0]["chq_no"])
        setDescrpition(dataForEdit["outward_vchentry"][0]["vch_desc"])
        setBank(dataForEdit["outward_vchentry"][0]["bank_name"])
        console.log("9999999", EditVoucherDate, EditCheckDate)
        setChequeDate(EditCheckDate)
        setVoucherDate(EditVoucherDate)
        console.log("calling here")
      } else {
        console.log('Error  On Vouher Edit');
      }
    }
    checkForEdit()
  }, [dataForEdit])

  // console.log("DatesssssssssssssDate", voucherDate, "444444444", chequeDate)
  // console.log("resulthhhhhhhhhhhhhhh", dayjs(voucherDate).format("YYYY-MM-DD"))
  // console.log("voucherRows,selectAccount", voucherRows, selectAccount);


  console.log("accountMasterrrrrrrrrrrrrrrrrrrr", accountMaster)

  const handleChangeScheme = (event) => {
    let { value } = event.target;
    setselectedSchemeName(value)
    let aa = schemeMaster.filter((item) => item.name === value)
    setSelectScheme({
      schemeName: aa[0].name,
      schemeId: aa[0].id
    });
  };


  const handleChangeForAcc = (event, newValue) => {
    // let filterdValue = accountMaster.filter((item,i)=>item.acc_name.toLowerCase()===newValue.acc_name.toLowerCase())
    // setselectedAccountName(newValue.acc_name)
    console.log("handleChangeForAcc", newValue)
    !!newValue && setSelectAccount({
      acc_name: newValue.acc_name,
      acc_id: newValue.acc_id
    });
  }


  const ChangeVoucherType = (event) => {
    setNewVoucher(event.target.value);
  };

  const changeVoucherno = (event) => {
    setVoucherno(event.target.value);
  };

  const ChangeBank = (event) => {
    setBank(event.target.value);
  };

  const handleChangeMonth = (event) => {
    setSelectedMon(event.target.value);
  };

  const ChangeChequeNo = (event) => {
    setChequeNo(event.target.value);
  };

  const handleChangeDes = (event) => {
    setDescrpition(event.target.value)
  }


  // optimize the two submit code
  const SubmitAll = async () => {
    if (!voucherno || !voucherDate || !description) {
      alert("Voucher Date or Voucher Number or Description can't be empty");
      return;
    }
    let table = "outward_vchentry_view";
    let temp = {};
    temp["Scheme_name"] = selectedSchemeName;
    temp["vch_no"] = voucherno;
    temp["Vch_type"] = newVoucher;
    temp["Vch_date"] = dayjs(voucherDate).format("YYYY-MM-DD");
    temp["Chq_no"] = chequeNo;
    temp["Chq_date"] = chequeDate ? chequeDate.format("YYYY-MM-DD") : null;
    temp["Bank_name"] = bank;
    temp["Vch_desc"] = description;
    temp["total_Amount"] = totalAmount(voucherRows, "accountTo");
    temp["table_name"] = table;

    let vchInEntSync = voucherRows.map((item, i) => ({
      ...item, vch_no: voucherno,
      scheme_name: selectedSchemeName,
      vch_date: (temp['Vch_date'])
    }))
    console.log("//////////////////", vchInEntSync)

    try {
      const firstApiResponse = await axios.post(`${baseUrl}insert_outward_voucher`, temp);
      if (firstApiResponse.data === "Record Inserted") {
        const secondApiResponse = await axios.post(`${baseUrl}insert_inword_voucher`, vchInEntSync);
        alert("Successfully inserted");
        setselectedSchemeName("");
        setChequeNo("");
        setVoucherDate(null);
        setChequeDate(null);
        setVoucherno("");
        setBank("");
        setNewVoucher("");
        setVouncherRows([]);
        setDescrpition("");
      }
    } catch (error) {
      console.log('voucher entey error', error);
      alert(error)
    }
  }
  console.log("?????????????>>>>>>>>>", chequeDate)


  // submit after Editing voucher 
  const submitEditAll = async () => {
    let vchInEntSync = voucherRows.map((item, i) => ({
      ...item,
      vch_no: voucherno,
      scheme_name: selectedSchemeName,
      vch_date: dayjs(voucherDate).format("YYYY-MM-DD")
    }))

    let temp = {};
    temp["Scheme_name"] = selectedSchemeName;
    temp["vch_no"] = voucherno;
    temp["Vch_type"] = newVoucher;
    temp["Vch_date"] = dayjs(voucherDate).format("YYYY-MM-DD");
    temp["Chq_no"] = chequeNo;
    temp["Chq_date"] = chequeDate ? chequeDate.format("YYYY-MM-DD") : "0000-00-00";
    temp["Bank_name"] = bank;
    temp["Vch_desc"] = description;
    temp["total_Amount"] = totalAmount(voucherRows, "accountTo");
    temp["inWord_allRows"] = vchInEntSync;


    try {
      const firstApiResponse = await axios.post(`${baseUrl}insert_Edited_voucher`, temp);
      if (firstApiResponse.data === "Data inserted successfully") {
        alert("Edit inserted");
        setselectedSchemeName("");
        setChequeNo("");
        setVoucherDate(null);
        setChequeDate(null);
        setVoucherno("");
        setBank("");
        setNewVoucher("");
        setVouncherRows([]);
        setDateforEdit({});
        setVoucherEditing(false);
        setDescrpition("");
      }
    } catch (error) {
      console.log('edit voucher rror ', error);
      alert(error)
    }
  }

  function totalAmount(voucherRows, prop) {
    let sumAmount = voucherRows.map((voucherRows) => voucherRows[prop]).reduce((sum, i) => sum + i, 0)
    return sumAmount
  }

  // Add disable state  to To From in voucher
  const [disable, setDisable] = useState(false)
  const settingAccountFromZero = () => {
    setAccountTo(0)
    setDisable(true)
    setAccountFrom()
  }

  const settingAccountToZero = () => {
    setAccountFrom(0)
    setDisable(true)
    setAccountTo()
  }
  const handleChangeToFrom = (e) => {
    setAccountType(e.target.value);
    let toFrom = e.target.value;
    toFrom === "to" ? settingAccountToZero() : settingAccountFromZero();
    if (selectedSchemeName != "" && voucherType != "") {
      axios
        .post(`${baseUrl}get_all_accountDes`, {
          scheme_name: selectedSchemeName,
          voucher_type: newVoucher,
          to_from: e.target.value,
        })
        .then((res) => {
          setaccountMaster(res.data);
        })
        .then(console.error("no data"));
    }
  };

  const enterAmountTo = (event) => {
    setAccountTo(event.target.value)
  }

  const enterAmountFrom = (event) => {
    setAccountFrom(event.target.value)
  }

  function createVoucherRow(accountTy, accountTo, accountFrom, newVoucher, selectAccount) {
    const parsedAccountTo = parseFloat(accountTo);
    const parsedAccountFrom = parseFloat(accountFrom);
    let vch_type = newVoucher;
    // let vch_date = voucherDate && dayjs(voucherDate).format("YYYY-MM-DD");
    let acc_id = !!selectAccount.acc_id && selectAccount.acc_id;

    return {
      accountTy,
      accountDesName: selectAccount.acc_name,
      accountTo: isNaN(parsedAccountTo) ? 0 : parsedAccountTo,
      accountFrom: isNaN(parsedAccountFrom) ? 0 : parsedAccountFrom,
      vch_type,
      // vch_date,
      acc_id
    };
  }


  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      let newRow = createVoucherRow(accountType,accountTo, accountFrom, newVoucher, selectAccount)
      setVouncherRows([...voucherRows, newRow])
      setAccountType("")
      setselectedAccountName("")
      setAccountTo(null)
      setAccountFrom(null)
      setSelectAccount({
        acc_name: "",
        acc_id: ""
      })
    }
  }
  console.log("<<<<<>>>>>>", voucherRows)
  const [duplicateVchCheck, setDuplicateVchchck] = useState(false)

  const checkExistingVchNo = async () => {
    const response = await axios.post(`${baseUrl}check-existing-vchno`, { vch_number: voucherno })
    if (response.data === "vch_no already exists") {
      alert("This Voucher number already exists.");
    }
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", mt: voucherEditing ? "100px" : "0px" }}>
      {!voucherEditing ? <h4>Voucher Entrey</h4> : <h4>Edit Voucher</h4>}
      <Box sx={{ justifyContent: "center" }}>
        {/* scheme */}
        <Box sx={{ display: 'flex', gap: "5px", }} >
          <Box>
            <InputLabel id="Account_select_lebel">Scheme name</InputLabel>
            <Select
              sx={{ width: 225, height: "40px", border: "", borderRadius: "8px" }}
              id="Scheme_select"
              placeholder='Select a Scheme Name'
              onChange={(event) => handleChangeScheme(event)}
              value={selectedSchemeName}
            >
              {schemeMaster && schemeMaster.map((data) => (
                <MenuItem key={data.id} value={data.name}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* type of votcher */}
          <Box>
            <InputLabel id="Account_select_lebel">Type of Voucher</InputLabel>
            <Select
              sx={{ width: 225, height: "40px", border: "", borderRadius: "8px" }}
              id="Voucher_select"
              // placeholder='Select a Scheme Name'
              onChange={(event) => ChangeVoucherType(event)}
              value={newVoucher}
            // readOnly={editFlag && true}
            >
              {voucherType.map((data, index) => (
                <MenuItem value={data}>{data}</MenuItem>
              ))}
            </Select>
          </Box>
          {/* voucher No */}
          <Box>
            <InputLabel id="Account_select_lebel">Voucher No</InputLabel>
            <StyledInput
              width="200px"
              onChange={(event) => changeVoucherno(event)}
              value={voucherno}
              disabled={voucherEditing}
              onBlur={!!voucherno && checkExistingVchNo}
            />
          </Box>
          {/* voucherDate */}
          <Box>
            <InputLabel id="Account_select_lebel" >Voucher Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']} sx={{ paddingTop: "0px" }}>
                <DatePicker value={voucherDate} onChange={(newValue) => setVoucherDate(newValue)}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "40px",
                      padding: 0
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: "5px" }}>
          {/*Cheque No */}
          <Box>
            <InputLabel id="Account_select_lebel">Cheque No</InputLabel>
            <StyledInput
              width="200px"
              onChange={(event) => ChangeChequeNo(event)}
              value={chequeNo}
            />
          </Box>
          {/* bankname */}
          <Box>
            <InputLabel id="Account_select_lebel">Bank Name</InputLabel>
            <StyledInput
              width="430px"
              onChange={(event) => ChangeBank(event)}
              value={bank}
              disabled={!chequeNo && true}
            />
          </Box>
          {/* chaeck date */}
          <Box>
            <InputLabel id="Account_select_lebel">Check Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']} sx={{ paddingTop: "0px" }}>
                <DatePicker
                  value={chequeDate}
                  onChange={(newValue) => setChequeDate(newValue)}
                  disabled={!chequeNo && true}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "40px",
                      padding: 0
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Box>

        {/* Descrpition */}
        <Box>
          <InputLabel id="Account_select_lebel">Descrpition</InputLabel>
          <StyledInput
            width="900px"
            height="25px"
            multiline
            rows={2}
            onChange={(event) => handleChangeDes(event)}
            defaultValue={description}
          />
        </Box>
      </Box>

      {/* voucher Entery  Table */}
      <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap", justifyContent: "center" }}>
        <Box>
          <InputLabel id="to-from">TO/FROM</InputLabel>
          <Select
            sx={{ width: 200, height: "40px", border: "", borderRadius: "8px" }}
            id="Budget_select"
            placeholder='Select a Budget Name'
            value={accountType}
            onChange={handleChangeToFrom}
          >
            <MenuItem value="to">TO</MenuItem>
            <MenuItem value="from">FROM</MenuItem>
          </Select>
        </Box>

        {/* --------------select option for Account-name------- */}
        {/* <Box>
          <InputLabel id="account-Name">Account Name</InputLabel>
          <Select
            sx={{ width: 200, height: "40px", border: "", borderRadius: "8px" }}
            id="Account_name"
            value={selectedAccountName}
            onChange={handleChangeForAcc}
          >
            {accountMaster.map((data) => (
              <MenuItem key={data.acc_id} value={data.acc_name}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    {data.acc_name}
                  </div>
                  <div>
                    -- {data.acc_id}
                  </div>
                </div>
              </MenuItem>
            ))}
          </Select>
        </Box> */}

        {/* -----Autocomplete need to be  added here------ */}
        <Box>
          <InputLabel id="account-Name">Account Name</InputLabel>
          <Autocomplete
            sx={{ width: 300, height: "40px", border: "", borderRadius: "8px" }}
            options={accountMaster}
            getOptionLabel={(option) => option.acc_name || ''}
            value={selectAccount}
            isOptionEqualToValue={(option,value)=>option.value===value.value}
            renderOption={(props, option) => (
              <Box component="li" sx={{ width: 400, fontSize: "16px", fontWeight: "bold" }} {...props}>
                {option.acc_name}--{option.acc_id}
              </Box>
            )}
            renderInput={params => (
              <TextField {...params} label="" variant="filled" />
            )}
            onChange={(event, newValue) => handleChangeForAcc(event, newValue)}
          />
        </Box>

        
        <Box>
          <InputLabel id="account-to">Account To</InputLabel>
          <StyledInput
            value={accountTo == null ? "" : accountTo}
            onChange={(event) => enterAmountTo(event)}
            onKeyPress={(e) => onEnterPress(e)}
          />
        </Box>

        <Box>
          <InputLabel id="account-from">Account From</InputLabel>
          <StyledInput
            value={accountFrom == null ? "" : accountFrom}
            onChange={(event) => enterAmountFrom(event)}
            onKeyPress={(e) => onEnterPress(e)}
          />
        </Box>
      </Box>

      {/* ----------- To FROM Table------------ */}
      <VoucherTable
        voucherRows={voucherRows}
        setVouncherRows={setVouncherRows}
        setSubmitTrue={setSubmitTrue}
        submitTrue={submitTrue}
        totalCredit={totalAmount(voucherRows, "accountTo")}
        totalDebit={totalAmount(voucherRows, "accountFrom")} />
      <Box>
        {totalAmount(voucherRows, "accountTo") === totalAmount(voucherRows, "accountFrom") && <button onClick={voucherEditing ? submitEditAll : SubmitAll}>
          {!voucherEditing ? "submit" : "submit Edit"}
        </button>}
      </Box>
    </Box>
  )
}

export default VoucherEntey