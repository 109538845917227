import React from 'react'
import { useState } from 'react'
import dayjs from "dayjs";

function useVoucher() {

  const [inWardDataForEdit, setInWarDataForEdit] = useState([])
  const [outWardDataForEdit, setOutWarDataForEdit] = useState([])
  const [dataForEdit, setDateforEdit] = useState({})
  const [voucherEditing, setVoucherEditing] = useState(false)
  const [voucherStartDate, setVoucherStartDate] = useState(null)
  const [voucherEndDate, setVoucherEndDate] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false);
  const [schemeMaster, setSchemetMaster] = useState([]);
  // For DayBook , and consTrail
  const [selectedSchemeName, setselectedSchemeName] = useState("")

  return {
    dataForEdit,
    setDateforEdit,
    voucherEditing,
    setVoucherEditing,
    inWardDataForEdit,
    setInWarDataForEdit,
    outWardDataForEdit,
    setOutWarDataForEdit,
    voucherStartDate, setVoucherStartDate, voucherEndDate, setVoucherEndDate,isAdmin, setIsAdmin,selectedSchemeName, setselectedSchemeName,
    schemeMaster, setSchemetMaster
  }
}

export default useVoucher