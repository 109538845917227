import logo from './logo.svg';
import React,{useState,useEffect} from "react"
import './App.css';
import Header from './component/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SchemeMaster from './pages/SchemeMaster';
import AccountEntry from './pages/AccountEntry';
import OBmust from './pages/OBmust';
import OBMaster from './pages/OBMaster';
import VoucherEntey from './pages/VoucherEntey';
import VoucherEdit from './pages/VoucherEdit';
import VoucherContextProvider from './context/VoucherContext';
import DayBook from './pages/DayBook';
import ConsTrailBook from './pages/ConsTrailBook';
import useImportVoucher from './hooks/useImportVoucher';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { fetchMasterData } from './Util/CommonFun/CommonFun';
import Login from './pages/Login';
import ProtectedRoute from './component/ProtectedRoute';

function App() {

  const {setSchemetMaster}=useImportVoucher()
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    async function fetchingSchemeToState() {
      try {
        const response = await fetchMasterData("scheme");
        console.log("res APp", response)
        setSchemetMaster(response);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchingSchemeToState();
  }, [])


  return (
    <div className='App-header'>
        <BrowserRouter>
          {/* <VoucherContextProvider> */}
          <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
          <Routes>
            <Route>
              <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />}  />
              <Route path="Scheme-Entries" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                     <SchemeMaster />
                </ProtectedRoute>
              } />
              <Route path="Bud-Acc-Entries" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <AccountEntry />
                </ProtectedRoute>
              } />
              <Route path="Bud-Acc-Scheme-Entries" element={
                 <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <OBmust />
                 </ProtectedRoute>
              } />
              <Route path="OBmaster-Entries" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                     <OBMaster />
                </ProtectedRoute>
              } />
              <Route path="Voucher-entry" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                   <VoucherEntey />
                </ProtectedRoute>
              } />
              <Route path="voucher-Edit" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                   <VoucherEdit />
                </ProtectedRoute>
                } />
              <Route path="DayBook" element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DayBook />
                </ProtectedRoute>
              } />
              <Route path="ConsTrailBook" element={
                 <ProtectedRoute isAuthenticated={isAuthenticated}>
                     <ConsTrailBook />
                </ProtectedRoute>
              } />
            </Route>
          </Routes>
          {/* </VoucherContextProvider> */}
        </BrowserRouter>
      {/* <Header/>
      <SchemeMaster/> */}
    </div>
  );
}

export default App;
