import React, { useState, useEffect } from 'react';
import axios from "axios";
import useImportVoucher from '../hooks/useImportVoucher';
import { useNavigate } from 'react-router-dom';
import {StyledInput } from '../Util/Comp/SmallComp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DataTable from "../Util/Comp/Table"

import { Alert, Box, Button, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';

function VoucherEdit() {

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { setDateforEdit, setVoucherEditing, schemeMaster } = useImportVoucher();
  const { selecedSchemeForEdit, setSelectedSchemeForEdit } = useState("")

  console.log('""""""""', schemeMaster);

  const navigate = useNavigate()

  const [findVoucherNo, setFindVoucherNo] = useState(" ")
  const [recentVoucher, setrecentVoucher] = useState([])
  const [selectSchemeinEdit, setSelectSchemeinEdit] = useState("");
  const [startDateEdit, setStartDateEdit] = useState(null)
  const [endDateEdit, setEndDateEdit] = useState(null)
  const [voucherList, setVoucherList] = useState(null)

  useEffect(() => {
    fectingRecentAddVoucher()
  }, [])

  async function fectingRecentAddVoucher() {
    try {
      const recentAddedVoucher = await axios.get(`${baseUrl}recent_add_voucher`);
      setrecentVoucher(recentAddedVoucher.data.recentVoucheList);
    } catch (err) {
      alert("Error occur in Fetching Data")
    }
  }

  console.log("end, start", endDateEdit, startDateEdit)

  const callDB = async (findVoucherNo) => {
    console.log(".................",findVoucherNo)
    try {
      const response = await axios.post(`${baseUrl}search_voucher`, { vch_no: findVoucherNo })
      console.log("......1111111111",findVoucherNo)
      const Record = response.data;
      if (typeof (Record) === "object") {
        setDateforEdit(Record);
        setVoucherEditing(true);
        console.log("213654")
        navigate("/Voucher-Entry")
      } else {
        alert("PLease enter  a valid Voucher number");
      }
    }
    catch (err) {
      console.log('Error', err);
    }
  }

  const onSubmit = (e) => {
    if (e.key === "Enter") {
      callDB(findVoucherNo)
    }
  }

  const handleChangeScheme = (event) => {
    console.log("vvvvvvvvvvvvvvvvvvv", event.target.value)
    setSelectSchemeinEdit(event.target.value);
  };

  const getVouchersforEdit = async () => {
    try {
      if (!selectSchemeinEdit) {
        console.log("date error scheme")
        alert("Please select a scheme");
        return;
      }
  
      if ((!startDateEdit && endDateEdit.format("YYYY-MM-DD")) || (startDateEdit.format("YYYY-MM-DD") && !endDateEdit)) {
        console.log("date error")
        alert("Please select both start and end dates");
        return;
      }
  
      let temp = {
        scheme_name: selectSchemeinEdit,
        start_date: startDateEdit && startDateEdit.format("YYYY-MM-DD"),
        end_date: endDateEdit && endDateEdit.format("YYYY-MM-DD"),
      };
  
      const res = await axios.post(`${baseUrl}VoucherList_forEdit`, { temp });
      if (!!res) {
        setVoucherList(res.data);
      } else {
        alert("No records found for this date range");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      alert("An error occurred while fetching data");
    }
  };
  

  let aaaa;
  console.log("voucherList", voucherList);
  
  function mapDataWithId() {
      aaaa = voucherList.map((item, index) => ({
          ...item,
          id: index + 1,
          vch_date: new Date(item.vch_date).toISOString().split('T')[0]
      }));
      return aaaa;
  }

  const handeleditFromTable = async (e) => {
    console.log(e)
    callDB(e.vch_no)
  }
  
  const DeleteVoucher = async (vch_no) => {
    console.log("fffffffffffff", typeof(vch_no))
    let temp = {
        vch_no: vch_no,
        scheme_name: selectSchemeinEdit,
        start_date: startDateEdit && startDateEdit.format("YYYY-MM-DD"),
        end_date: endDateEdit && endDateEdit.format("YYYY-MM-DD"),
    }
    try {
        const response = await axios.post(`${baseUrl}DeleteVoucher`, { temp })
        console.log("response",response)
        if (response.data.length >= 0) {
            setVoucherList(response.data)
            window.alert("Voucher successfully deleted.")
        }
        if (response.data.response==="Record successfully deleted."){
          fectingRecentAddVoucher()
          window.alert("Voucher successfully deleted.")
        }
        else {
          window.alert("Voucher is not deleted")
        }
    } catch (err) {
        window.alert("Something went wrong, Try again.")
    }
  }


  const handleDeleteFromTable = async(e)=>{
    console.log("////////////",e)
    DeleteVoucher()
  }

  return (
    <Box sx={{ width: "100%" }} >
      <Box sx={{height:"50px", display:"flex",justifyContent:"center",alignItems:"center",fontSize:"24px",fontWeight:700}}>Voucher List and Edit</Box>
      <Box sx={{ display: "flex",paddingInline:"20px",justifyContent: "space-between" }}>
        <Box sx={{ width: "65%",mt:"20px" }}>
          <Box sx={{display:"flex", gap:"10px",justifyContent:"center"}}>
            <Typography>Enter Voucher Number :</Typography>
            <StyledInput type="text" onChange={(e) => setFindVoucherNo(e.target.value)} onKeyDown={(e) => onSubmit(e)} />
            <Button variant="contained" color="secondary" onClick={(e)=>callDB(findVoucherNo)}>Edit</Button>
            <Button variant="outlined" color="secondary" onClick={(e)=>DeleteVoucher(findVoucherNo)}>Delete</Button>
          </Box>
          <Box sx={{ display: "flex", gap: "5px",mt:"20px" ,justifyContent:'center', alignItems:"flex-end" }} >
            <Box>
              <InputLabel id="Account_select_lebel">Scheme name</InputLabel>
              <Select
                sx={{ width: 225, height: "40px", border: "", borderRadius: "8px" }}
                id="Scheme_select"
                placeholder='Select a Scheme Name'
                onChange={(event) => handleChangeScheme(event)}
                value={selectSchemeinEdit}>
                {schemeMaster.map((data) => (
                  <MenuItem key={data.id} value={data.name}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <InputLabel id="Account_select_lebel" >Voucher Date Start</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{ paddingTop: "0px" }}>
                  <DatePicker value={startDateEdit} onChange={(newValue) => setStartDateEdit(newValue)}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        height: "40px",
                        padding: 0
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box>
              <InputLabel id="Account_select_lebel" >Voucher Date End</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{ paddingTop: "0px" }}>
                  <DatePicker value={endDateEdit} onChange={(newValue) => setEndDateEdit(newValue)}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        height: "40px",
                        padding: 0
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Button variant="contained" color="secondary"  sx={{height:"40px" }}onClick={getVouchersforEdit}>Search</Button>
          </Box>
          {voucherList && <Box  sx={{mt:"30px"}}>
            <DataTable
              mapDataWithId={mapDataWithId}
              handleEdit={(e) => handeleditFromTable(e)}
              handleDelete={(e)=>DeleteVoucher(e)}
              TableName={"voucherEdit"}
            />
          </Box>}
        </Box>
        <Box sx={{ width: "30%", }}>
          <div style={{mb:"10px"}}><Typography sx={{fontSize:"20px", fontWeight:400}}>Last 10 voucher added</Typography></div>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ Width: 400,border:"2px solid black" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Scheme Name</TableCell>
                    <TableCell align="">Voucher No.</TableCell>
                    <TableCell align="">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentVoucher.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.scheme_name}
                      </TableCell>
                      <TableCell align="">{row.vch_no}</TableCell>
                      <TableCell align="">{row.total_Amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default VoucherEdit;
