import axios from "axios";
import dayjs from "dayjs";
import useImportVoucher from '../hooks/useImportVoucher';
import { useSearchParams   } from 'react-router-dom';
import React, { useRef, useEffect, useState, componentDidUpdate } from 'react';
import LoadingSpinner from "../Util/LoadingSpinner";
//import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Paper from '@mui/material/Paper';
import { formatDate, sortByKey, sortFromTo, sortToFrom } from "../Util/CommonFun/CommonFun";
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import CloseIcon from '@mui/icons-material/Close';


function ConsTrailBook() {
    
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const { voucherStartDate, setVoucherStartDate, voucherEndDate, setVoucherEndDate, isAdmin, setIsAdmin, selectedSchemeName, setselectedSchemeName } = useImportVoucher();
    // const [voucherStartDate, setVoucherStartDate] = useState(dayjs(""))
    // const [voucherEndDate, setVoucherEndDate] = useState(dayjs(""))
    const [loading, setLoading] = useState(false);
    const [postProcess, setPostProcess] = useState(false);
    const [processedDaybookData, setProcessedDaybookData] = useState([]);
    const [daybookDataObCb, setDaybookDataObCb] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [elementOutlet, setElementOutlet] = useState("");
    const [totalPayment, setTotalPayment] = useState(0);
    const node = useRef(null);
    const [searchParams] = useSearchParams();
    const lastUrl = searchParams.get('from') || '/';




    console.log("consolited", "voucherStartDate", voucherStartDate, "voucherEndDate", voucherEndDate, selectedSchemeName, ">>>>>>>>", isAdmin, );

    useEffect(() => {
        if (postProcess) {
            setElementOutlet(document.getElementById("report"));
            console.log(document.getElementById("report"));
        }
        !isAdmin && processDaybook()
    }, [])

    const processDaybook = async () => {
        setLoading(true)
        let temp = {};
        temp["Vch_start_date"] =  dayjs(voucherStartDate).format("YYYY-MM-DD");
        temp["Vch_end_date"] = dayjs(voucherEndDate).format("YYYY-MM-DD");
        //temp["Vch_start_date"] = "2023-12-03";
        //temp["Vch_end_date"] = "2023-12-06";
        let tempObCb = {};
        tempObCb["Vch_start_date"] =  dayjs(voucherStartDate).format("YYYY-MM-DD");
        tempObCb["Vch_end_date"] = dayjs(voucherEndDate).format("YYYY-MM-DD");
        tempObCb["Scheme_name"] = selectedSchemeName
        console.log("temp", temp);
        console.log("temp", tempObCb);

        try {
            const firstApiResponse = await axios.post(`${baseUrl}processDaybook`, tempObCb);
            if (firstApiResponse.data != undefined) {
                setLoading(false)
                setPostProcess(true)
                console.log(firstApiResponse.data);
                setProcessedDaybookData(firstApiResponse.data)
            }

            const fetchDaybookObCb = await axios.post(`${baseUrl}fetchDaybookObCb`, tempObCb);
            if (fetchDaybookObCb.data != undefined) {
                console.log("////MMM///MMMM", fetchDaybookObCb, "MMMMMM", fetchDaybookObCb.data.data[0]);
                alert("Prcoeesed Daybook OB-CB Data!!!");
                setLoading(false)
                setPostProcess(true)
                setDaybookDataObCb(fetchDaybookObCb.data.data[0])
            }

        } catch (error) {
            console.log('Daybook Processing errored:', error);
            setLoading(false)
            alert(error)
        }
    }

    const paymentAggregator = (voucherAmount) => {
        setTotalPayment(totalPayment + voucherAmount);
        console.log(totalPayment);
    }

    const generatePDF = () => {
        console.log(elementOutlet);
        console.log(document.getElementById('report'));
        // const report = new jspdf('portrait','pt','a4');
        // report.html(document.getElementById('report')).then(() => {
        //     report.save('report.pdf');
        // });
        const input = document.getElementById('report');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jspdf('portrait', 'pt', 'a4', true);
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
                //pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });
    }

        // }

    if (!postProcess) {
        return (
            <div className="App-header-daybook">
                <div ref={node} id="report">
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", }}>
                        <div><h4>DISTRICT  WELFARE OFFICE, KHORDHA </h4></div>
                        {<table>
                            <tr>
                                <td>
                                    <Box>
                                        <InputLabel id="Account_select_lebel" >Voucher Date Start</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']} sx={{ paddingTop: "0px" }}>
                                                <DatePicker value={voucherStartDate} onChange={(newValue) => setVoucherStartDate(newValue)}
                                                    sx={{
                                                        "& .MuiOutlinedInput-input": {
                                                            height: "40px",
                                                            padding: 0
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </td>
                                <td><Box>
                                    <InputLabel id="Account_select_lebel" >Voucher Date End</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']} sx={{ paddingTop: "0px" }}>
                                            <DatePicker value={voucherEndDate} onChange={(newValue) => setVoucherEndDate(newValue)}
                                                sx={{
                                                    "& .MuiOutlinedInput-input": {
                                                        height: "40px",
                                                        padding: 0
                                                    },
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box></td>
                                <td>
                                    <button onClick={processDaybook} loading={loading} disabled={loading}>Process Data...</button>
                                </td>
                            </tr>
                        </table>}
                        {loading ? <LoadingSpinner /> : <div></div>} {errorMessage && <div className="error">{errorMessage}</div>}
                    </Box>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <div>
                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", position: "relative" }}>
                        <Box sx={{ position: "absolute", ml: "800px", mt: "25px" }}>
                            <CloseIcon
                                onClick={(e) => setPostProcess(false)} />
                        </Box>
                        <Box><h4>DISTRICT  WELFARE OFFICE, KHORDHA</h4></Box>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>Scheme-</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>{selectedSchemeName}</Typography>,
                            <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>start-date-</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}> {voucherStartDate && dayjs(voucherStartDate).format("YYYY-MM-DD")}</Typography> ,
                            <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>end-date-</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}> {voucherEndDate && dayjs(voucherEndDate).format("YYYY-MM-DD")}</Typography>
                        </Box>
                    </Box>
                    <button onClick={generatePDF} type="primary" danger="true">Export Data...</button>
                    <div ref={node} id="report" style={{ position: "inherit"}}>
                        <TableContainer component={Paper}>

                            {/* its should be ob Data----------- */}

                            {!!daybookDataObCb && <Box sx={{ minWidth: 800, backgroundColor: "lavender", border: "1px dashed", overflow: "auto" }} >
                                <Box>
                                    <div>OB Cash:{daybookDataObCb.ob_cash}</div>
                                    <div>OB Bank:{daybookDataObCb.ob_bank}</div>
                                    <div>OB Adv:{daybookDataObCb.ob_adv}</div>
                                    <div>OB Total:{daybookDataObCb.ob_tot}</div>
                                </Box>
                            </Box>}

                            {/* ----main Table ------- */}
                            <Table sx={{ minWidth: 800, backgroundColor: "lavender",border:"dashed" }} size="small" aria-label="a dense table">
                                {/* ....... receive side ........ */}
                                <TableCell style={{ width: "50%", borderRight: "solid" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ fontSize: "18px", fontWeight: 700 }}>Daybook Received</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {processedDaybookData.datareceivedres.map((val, key) => {
                                            return (
                                                <TableRow key={key} style={{ borderBottom: "dashed", overflowX: "auto" }}>
                                                    <Box sx={{ mt: "10px" }}> Voucher No:{val.receipt_vch_no}   |     Voucher Date:{formatDate(val.receipt_vchno_date)}</Box>
                                                    <Box sx={{ my: "5px" }}>{val.receipt_vch_desc}</Box>
                                                    <Box sx={{ mb: "10px" }}>
                                                        {sortToFrom(val.transactions, "receipt_vch_ty").map((trxndata, trxndatakey) => (
                                                            <TableRow key={trxndatakey} sx={{ width: '100%', marginBottom: '10px' }}>
                                                                <TableCell sx={{ width: "10%" }}>{trxndata.receipt_vch_ty}</TableCell>
                                                                <TableCell sx={{ width: "50%" }}>{trxndata.account_desc}</TableCell>
                                                                <TableCell sx={{ width: "10%" }}>{trxndata.receipt_vch_type}</TableCell>
                                                                <TableCell sx={{ width: "10%" }}>{trxndata.receipt_vch_amt}</TableCell>
                                                                <TableCell sx={{ width: "20%" }}>
                                                                    {trxndata.receipt_vch_ty == "to" ? <div colSpan={15} style={{ border: "solid" }}>
                                                                        {trxndata.receipt_vch_amt}
                                                                    </div> : ""}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </Box>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </TableCell>

                                {/* ...Payment side ..... */}
                                <TableCell style={{ width: "50%", borderLeft: "solid" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ fontSize: "18px", fontWeight: 700 }} >Daybook Payment</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ mb: "10px" }}>
                                        {processedDaybookData.datapaymentres.map((val, key) => {
                                            return (
                                                <TableRow key={key} style={{ borderBottom: "dashed", overflowX: "auto", paddingTop: "5px", marginBottom: '20px' }} sx={{ marginBottom: '20px' }}>

                                                    <Box sx={{ mt: "10px" }}>Voucher No:{val.payment_vch_no} |Voucher Date:{formatDate(val.payment_vch_date)}</Box>
                                                    <Box sx={{ my: "5px" }}>{val.payment_vch_desc}</Box>
                                                    <Box sx={{ mb: "10px" }}>

                                                        {sortFromTo(val.transactions, "payment_vch_ty").map((trxndata, trxndatakey) => (
                                                            <TableRow key={trxndatakey} sx={{ width: '100%', marginBottom: '10px' }}>
                                                                <TableCell sx={{ width: "10%" }}>{trxndata.payment_vch_ty}</TableCell>
                                                                <TableCell sx={{ width: "50%" }}>{trxndata.account_desc}</TableCell>
                                                                <TableCell sx={{ width: "10%" }}>{trxndata.payment_vch_type}</TableCell>
                                                                <TableCell sx={{ width: "10%" }}>{trxndata.payment_vch_amt}</TableCell>
                                                                <TableCell sx={{ width: "20%" }}>
                                                                    {trxndata.payment_vch_ty == "from" ? <div colSpan={15} style={{ border: "solid" }}>
                                                                        {trxndata.payment_vch_amt}
                                                                    </div> : ""}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </Box>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </TableCell>
                            </Table>

                            {/* ----------it should be cb data ------- */}
                            {!!daybookDataObCb && <Box sx={{ minWidth: 800, backgroundColor: "lavender", border: "1px dashed", overflow: "auto", display: "flex", flexDirection: "row-reverse" }}>
                                <Box sx={{ mr: "40px" }}>
                                    <div>CB Cash:{daybookDataObCb.cb_cash}</div>
                                    <div>CB Bank:{daybookDataObCb.cb_bank}</div>
                                    <div>CB Adv:{daybookDataObCb.cb_adv}</div>
                                    <div>Total CB:{daybookDataObCb.tot_cb}</div>
                                </Box>
                            </Box>}
                        </TableContainer>
                    </div>
                </div>
            </div>
        )
    }

}

export default ConsTrailBook;